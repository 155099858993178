import { createSelector } from '@ngrx/store';
import { min, some, uniq } from 'lodash-es';
import { vocabularyFreeLimit } from 'src/app/configuration';
import { Pronunciation } from 'src/app/model/pronunciation';
import { VocabEntriesStatus } from 'src/app/model/vocab-entries.status';
import { MembershipStatus } from 'src/app/subscription/membership-status';
import { membershipStatusSelector } from 'src/app/subscription/subscription-state-selectors';
import { UserType } from 'src/app/subscription/user-type';
import { getAssessmentParametersOfToday } from 'src/app/utility/reporting-utility';
import { newWordCountSelector } from 'src/app/vocab/vocab-state-selectors';
import { SchoolAdminRegistration } from '../../model/school-admin-registration';
import { appStateSelector } from '../../states/state-selectors';
import { vocabEntriesStatusSelector } from '../vocabulary/vocab-core-state-selectors';
import { userPreferenceSelector } from './user-preference-selectors';
import { UserState } from './user-state-reducer';

const userStateSelector = createSelector(
  appStateSelector,
  (state) => state.user
);

const userSelector = createSelector(
  userStateSelector,
  (state) => state.currentUser
);

export const userNameSelector = createSelector(userSelector, (user) => {
  if (user && user.profile) {
    return user.profile.preferred_username;
  } else {
    return undefined;
  }
});

export const userSubjectSelector = createSelector(userSelector, (user) => {
  if (user && user.profile) {
    return user.profile.sub;
  } else {
    return undefined;
  }
});

function getRoles(profile: any): string[] {
  if (profile && profile.role) {
    if (typeof profile.role === 'string') {
      return [profile.role];
    } else {
      return profile.role;
    }
  } else {
    return [];
  }
}

export const rolesSelector = createSelector(userSelector, (user) => {
  if (user && user.profile) {
    return getRoles(user.profile);
  } else {
    return [];
  }
});

export const isSysAdminSelector = createSelector(rolesSelector, (roles) =>
  roles.some((r) => r === 'SystemAdmin')
);

export const isImageAdminSelector = createSelector(rolesSelector, (roles) =>
  roles.some((r) => r === 'ImageAdmin')
);

export const isAuthorizedToUpdateSchoolAdminRegistrationSelector =
  createSelector(
    userStateSelector,
    isSysAdminSelector,
    (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
      if (isSystemAdmin) {
        return true;
      } else {
        return some(
          state.schoolAdminRegistrationsOfCurrentUser,
          (reg: SchoolAdminRegistration) =>
            reg.schoolId === schoolId &&
            reg.isActive &&
            reg.canUpdateRegistration
        );
      }
    }
  );

export const isAuthorizedToUpdateSchoolUserListSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId && reg.isActive && reg.canUpdateUserList
      );
    }
  }
);

export const isAuthorizedToSetUpCustomAssessmentPeriodsSelector =
  createSelector(
    userStateSelector,
    isSysAdminSelector,
    (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
      if (isSystemAdmin) {
        return true;
      } else {
        return some(
          state.schoolAdminRegistrationsOfCurrentUser,
          (reg: SchoolAdminRegistration) =>
            reg.schoolId === schoolId &&
            reg.isActive &&
            reg.canSetUpCustomAssessmentPeriods
        );
      }
    }
  );

export const isAuthorizedToManageExerciseReportSubscriptionSelector =
  createSelector(
    userStateSelector,
    isSysAdminSelector,
    (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
      if (isSystemAdmin) {
        return true;
      } else {
        return some(
          state.schoolAdminRegistrationsOfCurrentUser,
          (reg: SchoolAdminRegistration) =>
            reg.schoolId === schoolId &&
            reg.isActive &&
            reg.canManageExerciseReportSubscription
        );
      }
    }
  );

export const isAuthorizedToManageSyllabusSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId && reg.isActive && reg.canManageSyllabus
      );
    }
  }
);

export const isAuthorizedToManageSchoolPreferenceSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId &&
          reg.isActive &&
          reg.canManageSchoolPreference
      );
    }
  }
);

export const schoolPreferencesSelector = createSelector(
  userStateSelector,
  (s) => s.schoolPreferences
);

export const disableBatchVocabularyDeletionSelector = createSelector(
  schoolPreferencesSelector,
  (preferences) =>
    some(preferences.map((p) => p.disableBatchVocabularyDeletion))
);

export const disableIndividualWordDeletionSelector = createSelector(
  schoolPreferencesSelector,
  (preferences) => some(preferences.map((p) => p.disableIndividualWordDeletion))
);

export const disableUnattemptedWordsDeletionSelector = createSelector(
  schoolPreferencesSelector,
  (preferences) =>
    some(preferences.map((p) => p.disableUnattemptedWordsDeletion))
);

export const newVocabularyHardLimitSelector = createSelector(
  schoolPreferencesSelector,
  (preferences) => {
    if (preferences.length > 0) {
      return min(preferences.map((p) => p.newVocabularyHardLimit));
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }
);

export const newWordHardLimitReachedSelector = createSelector(
  newVocabularyHardLimitSelector,
  newWordCountSelector,
  (newVocabularyHardLimit: number, newWordCount: number) => {
    return newWordCount >= newVocabularyHardLimit;
  }
);

export const vocabFreeQuotaLimitReachedSelector = createSelector(
  membershipStatusSelector,
  vocabEntriesStatusSelector,
  (membershipStatus: MembershipStatus, entries: VocabEntriesStatus) => {
    if (
      membershipStatus.userType !== UserType.ActiveMember &&
      entries &&
      entries.totalCount >= vocabularyFreeLimit
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export const narrationPronunciationSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => {
    // if(userPreference.narrationPreference !== Pronunciation.Unspecified){
    //   return userPreference.narrationPreference;
    // }

    switch (userPreference.uiLanguageCode) {
      case 'en':
        return Pronunciation.AmericanEnglish;
      case 'zh-Hans':
        return Pronunciation.Putonghua;
      case 'zh-Hant':
        return Pronunciation.Cantonese;
      default:
        return Pronunciation.AmericanEnglish;
    }
  }
);

export const isActiveSchoolAdminSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId && reg.isActive
      );
    }
  }
);

export const isAuthorizedToPushNotificationSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId && reg.isActive && reg.canPushNotification
      );
    }
  }
);

export const isAuthorizedToEmailVocabularyReportsSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId &&
          reg.isActive &&
          reg.canEmailVocabularyReports
      );
    }
  }
);

export const isAuthorizedToManageExericiseRecordSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId &&
          reg.isActive &&
          reg.canManageExerciseRecord
      );
    }
  }
);

export const isAuthorizedToAdministrateAssessmentSelector = createSelector(
  userStateSelector,
  isSysAdminSelector,
  (state: UserState, isSystemAdmin: boolean, schoolId: string) => {
    if (isSystemAdmin) {
      return true;
    } else {
      return some(
        state.schoolAdminRegistrationsOfCurrentUser,
        (reg: SchoolAdminRegistration) =>
          reg.schoolId === schoolId &&
          reg.isActive &&
          reg.canAdministrateAssessment
      );
    }
  }
);

export const userInfoSelector = createSelector(
  userStateSelector,
  (state) => state.userInfo
);

const schoolAdminRegistrationsOfCurrentUserSchoolIdsSelector = createSelector(
  userStateSelector,
  (state) => state.schoolAdminRegistrationsOfCurrentUser.map((r) => r.schoolId)
);

const schoolUsersOfCurrentUserSchoolIdsSelector = createSelector(
  userStateSelector,
  (state) => state.schoolUsersOfCurrentUser.map((u) => u.schoolId)
);

export const schoolIdsSelector = createSelector(appStateSelector, (state) =>
  uniq([
    ...schoolAdminRegistrationsOfCurrentUserSchoolIdsSelector(state),
    ...schoolUsersOfCurrentUserSchoolIdsSelector(state)
  ])
);

export const customReportAttributesSelector = createSelector(
  userStateSelector,
  (state) => state.customReportAttributes
);

export const currentAssessmentPeriodSelector = createSelector(
  customReportAttributesSelector,
  (state) => {
    return getAssessmentParametersOfToday(state.assessmentPeriods);
  }
);
