export enum Pronunciation {
  BritishEnglish, //0
  AmericanEnglish, //1
  Putonghua, //2
  Cantonese, //3
  Japanese, //4
  Korean, //5
  AmericanSpanish, //6
  EuropeanSpanish, //7
  EuropeanFrench, //8
  CanadianFrench, //9
  German, //10
  Unspecified, //11
  EuropeanPortuguese, //12
  AmericanPortuguese, //13
  Afrikaans, //14
  Russian, //15
  Malay, //16
  Indonesian, //17
  Turkish, //18
  Arabic, //19
  Catalan, //20
  Italian, //21
  Polish, //22
  Urdu //23
}
