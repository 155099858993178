import { createSelector } from '@ngrx/store';
import { addMonths, addYears } from 'date-fns';
import { find, max, some } from 'lodash-es';
import { Duration } from '../model/duration';
import { appStateSelector } from '../states/state-selectors';
import { AppState } from '../states/states-reducers';
import { UserType } from '../subscription/user-type';
import { MembershipStatus } from './membership-status';
import { OrderItem } from './order-item';

const subscriptionStateSelector = (state: AppState) => state.subscription;

const membershipSelector = createSelector(
  subscriptionStateSelector,
  (state) => state.membership
);

export const hasUnlimitedAccessSelector = createSelector(
  appStateSelector,
  (appState: AppState, syllabusId: string) => {
    const syllabus = find(
      appState.syllabus.syllabi,
      (s) => s.id === syllabusId
    );
    if (syllabus && syllabus.isPremium) {
      const orderReferences = appState.subscription.membership.orderReferences;
      return some(orderReferences, (o) => o.programId === syllabusId);
    } else {
      //TODO
      const userType = UserType.ActiveMember; // userTypeSelector(appState);
      if (userType === UserType.ActiveMember) {
        return true;
      }
    }
    return false;
  }
);

export const orderItemsSelector = createSelector(
  membershipSelector,
  (membership) => {
    if (membership && membership.orderReferences) {
      return membership.orderReferences.map((o) => {
        return new OrderItem(
          o.invoiceNumber,
          new Date(o.createdUtc),
          o.amount,
          o.automaticRenewal,
          o.remark,
          o.isMembershipSubscription,
          o.validity
        );
      });
    } else {
      return [];
    }
  }
);

export const membershipStatusSelector = createSelector(
  membershipSelector,
  (membership) => {
    const freeMembership: MembershipStatus = {
      userType: UserType.FreeUser,
      expiryDate: new Date()
    };

    if (!membership || membership.orderReferences.length === 0) {
      return freeMembership;
    } else {
      const expiryDates = membership.orderReferences.map((o) =>
        calculateExpiryDate(new Date(o.createdUtc), o.validity)
      );

      var expiryDate = max(expiryDates);
      return {
        userType:
          expiryDate > new Date()
            ? UserType.ActiveMember
            : UserType.ExpiredMember,
        expiryDate
      };
    }
  }
);

function calculateExpiryDate(subscriptionDate: Date, validity: Duration): Date {
  switch (validity) {
    case Duration.Month:
      return addMonths(subscriptionDate, 1);
    case Duration.Year:
      return addYears(subscriptionDate, 1);
    case Duration.Lifetime:
      return addYears(subscriptionDate, 100);
    default:
      break;
  }
  return subscriptionDate;
}
