import { filter } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language } from '../../model/language';
import { Level } from '../../model/level';
import { QuestionType } from '../../model/question-type';
import { UICulture } from '../../model/ui-culture';
import { LoggingService } from '../log/logging.service';
import { ExerciseItemImplementation } from './exercise-item-implemenation';
import { PreloadingService } from './preloading.service';

export abstract class ExerciseItemsServiceBase {
  protected _showsQuestion$ = new BehaviorSubject<boolean>(true);
  protected _exerciseItems$ = new BehaviorSubject<ExerciseItemImplementation[]>(
    []
  );
  protected _readyToShowQuestion$ = new BehaviorSubject<boolean>(false);
  protected _canSendLevelsUpdate = false;
  protected _position = -1;
  protected _syllabusLanguage = Language.English;
  protected _uiCulture = UICulture.BritishEnglish;
  protected _dictionaryLevel = Level.Advanced;
  protected _startTimeInMs: number;
  protected _timeTakenInMs: number;
  protected _attempts : number;

  private answerIsCorrect : boolean;

  constructor(protected preloadingSerivce: PreloadingService, protected logger: LoggingService) {}

  hasOldQuestion: boolean;
  retentionRate: number;

  isTest = false;

  public skipQuestionsRequireCalculator = false;
  public score: number;
  public newQuestionsCount: number;
  public totalQuestionsCount: number;

  public cancel() {
    this.resetExerciseState();
  }

  resetExerciseState() {
    this._exerciseItems$.next([]);
    this._position = -1;
    this._syllabusLanguage = Language.English;
    this._showsQuestion$.next(true);
    this._readyToShowQuestion$.next(false);
    this._canSendLevelsUpdate = false;
    this.skipQuestionsRequireCalculator = false;
    this._attempts = 0;
  }

  private getHasOldQuestion(): boolean {
    return this.getOldQuestionCount() > 0;
  }

  get hasQuestion(): boolean {
    return (
      this._exerciseItems$ &&
      this._exerciseItems$.value &&
      this._exerciseItems$.value.length > 0
    );
  }

  private getNewQuestionsCount(): number {
    return this._exerciseItems$.value.filter((i) => i.isNew).length;
  }

  private getTotalQuestionsCount(): number {
    return this._exerciseItems$.value.length;
  }

  get current$(): Observable<ExerciseItemImplementation> {
    return of(this._exerciseItems$.value[this._position]);
  }

  get readyToShowQuestion$(): Observable<boolean> {
    return this._readyToShowQuestion$.asObservable();
  }

  get showsQuestion() {
    return this._showsQuestion$.value;
  }

  get questionsRemaining$(): Observable<number> {
    return this._exerciseItems$.pipe(
      map((items) => items.filter((i) => i.itemTrial > 0).length)
    );
  }

  get progress$() : Observable<number> {
    return this._exerciseItems$.pipe(
      map((items) => 100 * (items.filter((i) => i.itemTrial === 0).length)/items.length)
    );
  }

  private getScore(): number {
    const exerciseItems = this._exerciseItems$.value;
    const total = exerciseItems.length;
    if (total === 0) {
      return 0;
    }
    let correct = 0;
    for (let index = 0; index < total; index++) {
      const exerciseItem = exerciseItems[index];
      if (exerciseItem.isCorrectInAllAttempts) {
        correct++;
      }
    }
    const result = correct / total;
    return result;
  }

  protected startExercise(): Promise<any> {
    const phrases = this._exerciseItems$.value
      .map((i) => i.question)
      .filter((q) => q.type === QuestionType.Vocabulary)
      .map((i) => i.answers[0].text);

    return this.preloadingSerivce
      .preloadVocabularyData(
        phrases,
        this._dictionaryLevel,
        this._syllabusLanguage,
        this._uiCulture
      )
      .then((_) => {
        if (this.moveNext()) {
          this._readyToShowQuestion$.next(true);
          this._startTimeInMs = Date.now();
        }
      });
  }

  submitAnswer(isCorrect: boolean) {
    if(this.questionsRemaining === 0){
      this._canSendLevelsUpdate = true;
      this._showsQuestion$.next(false);
      this.dispatchExerciseCompletedCommand();
      return;
    }

    if (this._showsQuestion$.value) {
      const exerciseItem = this._exerciseItems$.value[this._position];
      exerciseItem.submitAnswer(isCorrect);
      this.answerIsCorrect = isCorrect;
      this._attempts++;
    } else{
      this.moveNext(this.answerIsCorrect);
    }
    this._showsQuestion$.next(!this._showsQuestion$.value);
  }

  private get questionsRemaining() : number{
    return this._exerciseItems$.value.filter(i => i.itemTrial > 0).length;
  }

  protected abstract dispatchExerciseCompletedCommand(): void;

  protected updateExerciseSummaryValues() {
    this._timeTakenInMs = this.getTimeTakenInMs();
    this.retentionRate = this.getRetentionRate();
    this.score = this.getScore();
    this.hasOldQuestion = this.getHasOldQuestion();
    this.newQuestionsCount = this.getNewQuestionsCount();
    this.totalQuestionsCount = this.getTotalQuestionsCount();
  }

  private getOldQuestionCount() {
    const exerciseItems = this._exerciseItems$.value;
    return exerciseItems.filter((i) => !i.isNew).length;
  }
  private getRetentionRate(): number {
    const oldQuestionCount = this.getOldQuestionCount();

    if (oldQuestionCount === 0) {
      return -1;
    }

    let OldQuestionIsCorrectInAllAttemptsCount = 0;

    const exerciseItems = this._exerciseItems$.value;
    exerciseItems.forEach((exerciseItem) => {
      const isOldQuestion = !exerciseItem.isNew;
      const isCorrectInAllAttempts = exerciseItem.isCorrectInAllAttempts;
      if (isOldQuestion && isCorrectInAllAttempts) {
        OldQuestionIsCorrectInAllAttemptsCount++;
      }
    });
    return OldQuestionIsCorrectInAllAttemptsCount / oldQuestionCount;
  }

  private getTimeTakenInMs(): number {
    return Date.now() - this._startTimeInMs;
  }

  private getNextIndex(): number {
    let index = this._position;
    const exerciseItems = this._exerciseItems$.value;
    const itemTrials = exerciseItems.map((i) => i.itemTrial);
    const questionsRemaining = filter(itemTrials, (t) => t > 0).length;
    if (questionsRemaining > 0) {
      do {
        index++;
        if (index >= itemTrials.length) {
          index = 0;
        }
      } while (itemTrials[index] === 0);
      return index;
    } else {
      return -1;
    }
  }

  private moveNext(isCorrect? : boolean): boolean {
    if(isCorrect === false){
      return true;
    }
    const nextIndex = this.getNextIndex();
    if (nextIndex === -1) {
      return false;
    } else {
      this._position = nextIndex;
      return true;
    }
  }
}
