import { createSelector } from '@ngrx/store';
import { appStateSelector } from '../../states/state-selectors';
import { AppState } from '../../states/states-reducers';
import { selectedSyllabusLanguageSelector } from '../syllabus/syllabus-state-selectors';

const vocabularyStateSelector = (state: AppState) => state.vocabCore;

const vocabEntriesStatusesSelector = createSelector(
  vocabularyStateSelector,
  (state) => state.vocabEntriesStatuses
);

export const vocabEntriesStatusSelector = createSelector(
  appStateSelector,
  (state) => {
    const syllabusLanguage = selectedSyllabusLanguageSelector(state);
    const vocabEntriesStatuses = vocabEntriesStatusesSelector(state);
    const vocabEntriesStatus = vocabEntriesStatuses[syllabusLanguage];
    return vocabEntriesStatus;
  }
);

export const totalVocabCountSelector = createSelector(
  vocabEntriesStatusSelector,
  (vocabEntriesStatus) => {
    if (vocabEntriesStatus) {
      return vocabEntriesStatus.totalCount;
    } else {
      return 0;
    }
  }
);

export const newVocabCountSelector = createSelector(
  vocabEntriesStatusSelector,
  (vocabEntriesStatus) => {
    if (vocabEntriesStatus) {
      return vocabEntriesStatus.newCount;
    } else {
      return 0;
    }
  }
);
