import { Amount } from '../model/amount';
import { Duration } from '../model/duration';

export class OrderItem {
  constructor(
    public invoiceNumber: number,
    public purchaseDate: Date,
    public amount: Amount,
    public automaticRenewal: boolean,
    public remark: string,
    public isMembershipSubscription: boolean,
    public validity: Duration
  ) {}
}
